import React from "react";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CardMedia from "@mui/material/CardMedia";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import TableRow from "@mui/material/TableRow";
import Layout from "../components/layout";
import Seo from "../components/seo";
import PDFViewer from "../components/pdf-viewer";
import {
    ProductButton,
    CustomTableRow,
    CustomTableContainer,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableCell,
    HeaderTitle,
    ProductPlaceholderImage,
} from "../styles/classes";
import Scoring from "../components/product-scores";
import ProductPlaceholder from "../images/no_image.png";
import OtherIngredientsTable from "../components/other-ingredients";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProductPage = ({ pageContext }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const productImage =
        pageContext.productImages && pageContext.productImages[0];

    const nullArray = [1, 2, 3, 4];
    const categories = pageContext.aggregateMeasurements && [
        ...new Set(
            pageContext.aggregateMeasurements.map(
                (ingredient) => ingredient.row.category
            )
        ),
    ];

    return (
        <Layout
            companyName={`${pageContext.companyName}`}
            companyPath={`${pageContext.companyPath}`}
        >
            <Seo
                title={`${pageContext.product.product_name} - ${pageContext.companyName}`}
                description={
                    pageContext.product.product_description || "nothin’"
                }
                article
            />
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    {productImage &&
                    productImage.s3Object &&
                    productImage.s3Object.localFile ? (
                        <CardMedia
                            component="img"
                            image={
                                productImage.s3Object.localFile.childImageSharp
                                    .fixed.src
                            }
                            alt="Product image"
                            sx={{ maxHeight: 800 }}
                            key={productImage.row.product_image_id}
                        />
                    ) : (
                        <ProductPlaceholderImage
                            component="img"
                            image={ProductPlaceholder}
                            alt="product image not available"
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        rowSpacing={3}
                        sx={{ padding: "1.5rem" }}
                    >
                        <Grid item sx={{ mt: 4, mb: 2 }} width="100%">
                            <Typography variant="h4" fontWeight={700}>
                                {pageContext.product.product_name}
                            </Typography>
                            <Typography variant="body2" color="gray">
                                {pageContext.product.product_description}
                            </Typography>
                        </Grid>
                        <Grid item width="100%">
                            {pageContext.productScores.map(
                                (product_score, index) => (
                                    <Scoring
                                        productScores={product_score}
                                        key={index}
                                    />
                                )
                            )}
                        </Grid>

                        <Grid item width="100%">
                            <Typography
                                fontWeight={700}
                                mt={4}
                                mb={2}
                                variant="h6"
                            >
                                Label Ingredients
                            </Typography>
                            <CustomTableContainer component={Paper}>
                                <Table size="small">
                                    <CustomTableHead>
                                        <TableRow>
                                            <CustomTableHeadCell width="30%">
                                                Ingredient
                                            </CustomTableHeadCell>
                                            <CustomTableHeadCell
                                                width="20%"
                                                align="center"
                                            >
                                                Label (mg)
                                            </CustomTableHeadCell>
                                            <CustomTableHeadCell
                                                width="24%"
                                                align="right"
                                            >
                                                Test (mg)
                                            </CustomTableHeadCell>
                                            <CustomTableHeadCell
                                                width="20%"
                                                align="right"
                                            >
                                                Var (%)
                                            </CustomTableHeadCell>
                                        </TableRow>
                                    </CustomTableHead>
                                    <TableBody>
                                        {(pageContext.aggregateMeasurements &&
                                            pageContext.aggregateMeasurements.map(
                                                (ingredient) =>
                                                    ingredient.row.amount && (
                                                        <CustomTableRow
                                                            key={
                                                                ingredient.row
                                                                    .ingredient_id
                                                            }
                                                        >
                                                            <CustomTableCell
                                                                width="30%"
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {
                                                                    ingredient
                                                                        .row
                                                                        .full_name
                                                                }
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                width="20%"
                                                                align="right"
                                                            >
                                                                {ingredient.row
                                                                    .amount &&
                                                                    `${parseFloat(
                                                                        ingredient
                                                                            .row
                                                                            .amount
                                                                    ).toFixed(
                                                                        2
                                                                    )}`}
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                width="24%"
                                                                align="right"
                                                            >
                                                                {ingredient.row
                                                                    .avg_measured_amount &&
                                                                    `${parseFloat(
                                                                        ingredient
                                                                            .row
                                                                            .avg_measured_amount
                                                                    ).toFixed(
                                                                        2
                                                                    )}`}
                                                            </CustomTableCell>
                                                            <CustomTableCell
                                                                width="20%"
                                                                align="right"
                                                            >
                                                                {ingredient.row
                                                                    .avg_variance_pct &&
                                                                    `${(
                                                                        parseFloat(
                                                                            ingredient
                                                                                .row
                                                                                .avg_variance_pct
                                                                        ) * 100
                                                                    ).toFixed(
                                                                        2
                                                                    )}%`}
                                                            </CustomTableCell>
                                                        </CustomTableRow>
                                                    )
                                            )) || (
                                            <CustomTableRow>
                                                {nullArray.map((index) => {
                                                    return (
                                                        <CustomTableCell
                                                            key={index}
                                                        >
                                                            {"N/A"}
                                                        </CustomTableCell>
                                                    );
                                                })}
                                            </CustomTableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </CustomTableContainer>
                            {categories &&
                                categories.map((item, index) => {
                                    return (
                                        (item !== "Inactive Ingredients" && (
                                            <OtherIngredientsTable
                                                category={item}
                                                pageContext={pageContext}
                                                tableLabel={`Other ${item}s`}
                                                key={index}
                                            />
                                        )) || (
                                            <OtherIngredientsTable
                                                category={item}
                                                pageContext={pageContext}
                                                tableLabel={`${item}s`}
                                                key={index}
                                            />
                                        )
                                    );
                                })}
                            <ProductButton onClick={handleClickOpen}>
                                See latest test result
                            </ProductButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                disableSpacing="true"
                maxWidth="lg"
            >
                <AppBar position="relative" color="secondary">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <HeaderTitle variant="body.subtitle">
                            {pageContext.product.product_name}
                        </HeaderTitle>
                    </Toolbar>
                </AppBar>
                {pageContext.testResults && (
                    <PDFViewer testResultUrl={pageContext.testResults[0]} />
                )}
            </Dialog>
        </Layout>
    );
};

export default ProductPage;
