import React from "react";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
    CustomTableRow,
    CustomTableContainer,
    CustomTableHead,
    CustomTableHeadCell,
    CustomTableCell,
} from "../styles/classes";

const OtherIngredientsTable = ({ category, pageContext, tableLabel }) => {
    return (
        <>
            <Typography fontWeight={700} mt={4} mb={2} variant="h6">
                {tableLabel}
            </Typography>
            <CustomTableContainer component={Paper}>
                <Table size="small">
                    <CustomTableHead>
                        <TableRow>
                            <CustomTableHeadCell width="25%">
                                Ingredient
                            </CustomTableHeadCell>
                            <CustomTableHeadCell width="24%" align="right">
                                Test (mg)
                            </CustomTableHeadCell>
                        </TableRow>
                    </CustomTableHead>
                    <TableBody>
                        {(pageContext.aggregateMeasurements &&
                            pageContext.aggregateMeasurements.map(
                                (ingredient) =>
                                    !ingredient.row.amount &&
                                    ingredient.row.category &&
                                    ingredient.row.category === category && (
                                        <CustomTableRow
                                            key={ingredient.row.ingredient_id}
                                        >
                                            <CustomTableCell
                                                width="25%"
                                                component="th"
                                                scope="row"
                                            >
                                                {ingredient.row.full_name}
                                            </CustomTableCell>

                                            <CustomTableCell
                                                width="26%"
                                                align="right"
                                            >
                                                {ingredient.row
                                                    .avg_measured_amount &&
                                                    `${parseFloat(
                                                        ingredient.row
                                                            .avg_measured_amount
                                                    ).toFixed(2)}`}
                                            </CustomTableCell>
                                        </CustomTableRow>
                                    )
                            )) || (
                            <CustomTableRow>
                                <CustomTableCell>{"N/A"}</CustomTableCell>
                                <CustomTableCell>{"N/A"}</CustomTableCell>
                            </CustomTableRow>
                        )}
                    </TableBody>
                </Table>
            </CustomTableContainer>
        </>
    );
};

export default OtherIngredientsTable;
