import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { PdfStyles } from "../styles/classes";

if (typeof window !== `undefined`) {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
}

const PDFViewer = ({ testResultUrl }) => {
    const [numPages, setNumPages] = useState(null);
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <Grid direction="column" rowSpacing={3} sx={{ marginTop: 1 }}>
            {testResultUrl && isClient ? (
                <Document
                    styles={PdfStyles.document}
                    file={testResultUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                >
                    {Array.from(new Array(numPages), (el, index) => (
                        <Grid item key={`page_${index + 1}`}>
                            <Page
                                pageNumber={index + 1}
                                styles={PdfStyles.page}
                            />
                        </Grid>
                    ))}
                </Document>
            ) : (
                <Typography
                    variant="h5"
                    align="center"
                    m={4}
                    sx={{ minHeight: 250 }}
                >
                    {JSON.stringify(testResultUrl)}
                </Typography>
            )}
        </Grid>
    );
};

export default PDFViewer;
