import React from "react";
import { ProductCircleScoring } from "../styles/classes";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ProvenanceScores, OverAllScore } from "./score-component";
import { Theme } from "../styles/theme";

const Scoring = ({ productScores }) => {
    const colorMapper = Object.values(Theme.palette.scoringColor);

    return (
        <Grid container justifyContent="space-evenly" columns={10}>
            <Grid
                item
                xs={2}
                sm={3}
                md={2}
                lg={3}
                align="center"
                alignSelf="center"
            >
                <OverAllScore
                    overall_score={productScores.row.overall_score}
                    width="70px"
                    height="70px"
                    position="relative"
                />
                {/* IN NEED FOR LATER USAGE */}
                {/* {(productScores.row.overall_score !== -1 && (
                    <IndicatorWrapper>
                        <CustomIndicatorBar
                            getAriaLabel={() => "Minimum distance"}
                            value={[20, 47]}
                            valueLabelDisplay="on"
                            getAriaValueText={valuetext}
                            disableSwap
                        />
                        <CustomProgressBar
                            aria-label="Scoring"
                            defaultValue={70}
                            getAriaValueText={valuetext}
                            disabled
                        />
                    </IndicatorWrapper>
                )) ||
                    null} */}
            </Grid>

            <Divider orientation="vertical" flexItem />

            <Grid
                container
                columns={10}
                justifyContent="space-evenly"
                alignSelf="center"
                xs={6}
                sm={6}
                md={7}
                lg={6}
                item={true}
            >
                <ProvenanceScores productScores={productScores} />
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item>
                <Typography fontSize={8}> best </Typography>
                {colorMapper.map((value, index) => {
                    return <ProductCircleScoring bgcolor={value} key={index} />;
                })}
                <Typography fontSize={8}> worse </Typography>
            </Grid>
        </Grid>
    );
};

export default Scoring;
